// services/authService.js
import api from '../config/axiosConfig'; // Import the custom Axios instance

export const loginUser = async (email, password) => {
  try {
    const response = await api.post('/auth/login', { email, password });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// register

export const registerUser = async (data) => {
  try {
    const response = await api.post('/auth/register',data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Check if user exists
export const checkUser = async ({ type, value }) => {
  try {
    const response = await api.get(`/auth/check-user`, { params: { type, value } });
    return response.data;
  } catch (error) {
    console.error("Error in checkUser:", error);
    throw error.response?.data || error.message;
  }
};